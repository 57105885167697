import { useLocation, useNavigate, useParams } from "react-router-dom";
import UseUsers from "../../../services/useUsers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BannerImage from "../../../assets/Images/WebImages/BannerImage.jpg";
import ReadMore from "../../../lib/ReadMore";
import { MdMessage } from "react-icons/md";
import UseChat from "../../../services/useChat";
import { useUserStore } from "../../../zustandStore/useUserStore";
import { useEffect } from "react";
import moment from "moment";
import getAuthState from "../../../hooks/getAuthState";

const OtherProfile = () => {
  const { guid } = useParams<{ guid: string }>();
  const { getUsersbyId } = UseUsers();
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);
  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const setChatGuid = useUserStore((state) => state.setChatGuid);
  const queryClient = useQueryClient();
  const location = useLocation();
  const chat_guid = location.state?.chat_guid;
  const navigate = useNavigate();

  const { postStartChat } = UseChat();

  const {
    data: user,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["users", guid],
    queryFn: getUsersbyId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const StartChatMutation = useMutation({
    mutationFn: postStartChat,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["chats"] });
      
      const chatData = {
        chat_guid: data.data.chat_guid,
        users: [user?.user],
        last_message: null,
        created_at: new Date().toISOString(),
        new_messages_count: 0
      };
      
      setChatGuid(data.data.chat_guid);
      setSelectedUser(chatData);
      setChatRoomOpen(true);
    },
  });

  const handleChatClick = () => {
    if (!chat_guid) {
      StartChatMutation.mutate(user?.user?.guid);
    } else {
      const chatData = {
        chat_guid: chat_guid,
        users: [user?.user],
        last_message: null,
        created_at: new Date().toISOString(),
        new_messages_count: 0
      };
      
      setChatGuid(chat_guid);
      setSelectedUser(chatData);
      setChatRoomOpen(true);
    }
  };

  const SkeletonLoader = () => (
    <div className="w-full h-screen">
      <div className="animate-pulse flex flex-col space-y-6 h-full md:w-[70dvw] w-screen">
        <div className="relative w-full h-48 bg-gray-400/70 bg-cover bg-center">
          <div className="absolute -bottom-12 left-2 md:left-4 md:-bottom-28">
            <div className="md:w-40 md:h-40 w-28 h-28 bg-gray-400/70 rounded-full border-4 border-light"></div>
          </div>
        </div>

        <div className="flex items-center space-x-4 md:mx-48 mx-4 gap-x-2 pb-8">
          <div className="flex-1 space-y-4">
            <div className="h-4 bg-gray-400/70 rounded md:w-40 w-28 mx-28 md:mx-0"></div>
            <div className="space-y-2">
              <div className="h-3 bg-gray-400/70 rounded w-64"></div>
              <div className="h-3 bg-gray-400/70 rounded w-64"></div>
              <div className="h-3 bg-gray-400/70 rounded w-64"></div>
            </div>
          </div>
        </div>

        <div className="mb-4 bg-gray-400/70 p-2 rounded relative h-36"></div>
      </div>
    </div>
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        navigate("/home", { state: { message: "Please Try Again" } });
      }
    }, 20000);

    return () => clearTimeout(timer);
  }, [isLoading, navigate]);

  return (
    <div className="w-full">
      {isLoading ? (
        <SkeletonLoader />
      ) : isError ? (
        <SkeletonLoader />
      ) : (
        <>
          <div
            className="relative w-full h-48 bg-cover bg-center hover:opacity-80 transition-opacity duration-300"
            style={{
              backgroundImage: `url(${user?.data?.background_image || BannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundColor: "unimayt",
            }}
          ></div>

          <div className="flex items-center md:mx-4 mx-3 gap-x-2 relative">
            <div className="relative group">
              <div className="md:w-44 md:h-44 w-28 h-28 bg-dark rounded-full relative top-1/2 transform -translate-y-1/2 lg:top-1/3 lg:-translate-y-1/3">
                <img
                  src={
                    user?.user?.user_image
                      ? user.user.user_image
                      : user?.user?.gender === "Female"
                        ? "/icons/female.png"
                        : "/icons/male.png"
                  }
                  alt="Profile"
                  className="w-full h-full rounded-full object-cover"
                />
              </div>
            </div>

            <div className="flex flex-col w-full md:ml-4">
              <div className="flex justify-between items-center md:-mt-6 -mt-12">
                <h2 className="md:text-subheading text-subheading font-heading md:-mt-12">{`${user?.user?.first_name} ${user?.user?.last_name}`}</h2>
                <div
                  className="flex items-center justify-center md:gap-2 rounded p-1 font-semibold md:mr-4 cursor-pointer w-fit h-fit hover:text-Button transition-all duration-300"
                  onClick={handleChatClick}
                >
                  <div className="hover:scale-110 transform transition-transform duration-300">
                    <MdMessage className="text-dark" size={28} />
                  </div>
                </div>
              </div>

              <div className="hidden md:block font-heading text-subparagraph md:text-subparagraph lg:-mt-5">
                <div className="font-semibold">
                  {user?.user?.university_name ? (
                    <>
                      {user.user.university_name} (
                      {user.user.campus_location || "Location not provided"})`
                    </>
                  ) : (
                    <div className="text-gray-500">
                      Not yet applied for university
                    </div>
                  )}
                </div>

                <div className="font-semibold">
                  {user?.user?.course_name ? (
                    <span className="font-semibold">
                      {`${user?.user?.course_name || "Enter your Course Enrollment..."} ${
                        user?.user?.course_start_date
                          ? `(${moment(user.user.course_start_date).format("YYYY-MMM")})`
                          : ""
                      }`}
                    </span>
                  ) : (
                    <div className="text-gray-500">No course enrolled</div>
                  )}
                </div>

                {user?.user?.city_name && (
                  <div>
                    <span className="font-heading">Home Location:</span>{" "}
                    <span className="font-semibold">
                      {user?.user?.city_name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="block md:hidden text-paragraph -mt-12 ml-4 mb-3 md:mb-0 md:ml-0 font-semibold text-subparagaph lg:mt-1">
            {user?.user?.university_name ? (
              <>
                {user.user.university_name} (
                {user.user.campus_location || "Location not provided"})
              </>
            ) : (
              <div className="text-gray-500 font-semibold">
                Not yet applied for university
              </div>
            )}

            <div className="font-semibold">
              {user?.user?.course_name ? (
                <span className="font-semibold">
                  {`${user?.user?.course_name || "Enter your Course Enrollment..."} ${
                    user?.user?.course_start_date
                      ? `(${moment(user.user.course_start_date).format("YYYY-MMM")})`
                      : ""
                  }`}
                </span>
              ) : (
                <div className="text-gray-500">No course enrolled</div>
              )}
            </div>

            {user?.user?.city_name && (
              <div>
                <span className="font-heading">Home Location:</span>{" "}
                <span className="font-semibold">{user?.user?.city_name}</span>
              </div>
            )}

            {user?.data?.city_name && (
              <div>
                <span className="font-heading">Home Location:</span>{" "}
                <span className="font-normal">{user?.data?.city_name}</span>
              </div>
            )}
          </div>
          <div className="border-t border-gray-400 lg:-mt-12"></div>
          <div className="flex flex-col px-3 items-start bg-Gray py-2 my-2 rounded-lg">
            <div className="w-full flex justify-between items-center ">
              <div className="text-paragraph font-heading">Journey so Far</div>
              <div></div>
              <section className="mt-1">
                <div className="text-paragraph font-normal max-h-24">
                  {!user?.user?.introduction && (
                    <div className="text-dark text-subparagraph text-wrap text-ellipsis line-clamp-1 italic">
                      Bio coming soon!
                    </div>
                  )}
                </div>
              </section>
            </div>
            <div className="text-subparagraph truncate italic font-paragraph max-h-34">
              {user?.user?.introduction ? (
                <ReadMore
                  id={""}
                  amountOfWords={28}
                  text={user?.user?.introduction}
                />
              ) : (
                <div className="text-dark truncate italic text-subparagraph mt-2">
                  {`${user?.user?.first_name}`} hasn't added any milestones to
                  their journey yet...
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OtherProfile;